import React from 'react'
import useEditCampaign from './hooks'
import { useMutation } from '@apollo/client'

import { CampaignEditor } from './CampaignEditor'
import Title from '../components/Title'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useNavigate } from 'react-router-dom'
import { AddNewCampaignDocument, RefetchCampaignsDocument } from '../generated'

const AddCampaignScreen = () => {
  const {
    campaign,
    discounts,
    handleValueChange,
  } = useEditCampaign()
  const [addNewCampaign] = useMutation(AddNewCampaignDocument)
  const navigate = useNavigate()

  const handleCampaignSubmit = () => {
    const { id, active, labels, ...fields } = campaign
    addNewCampaign({
      variables: {
        input: {
          ...fields,
       },
      },
      refetchQueries: [{ query: RefetchCampaignsDocument }],
    }).then((data) => navigate(`/campaigns/${data.data.addNewCampaign.id}`))
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Create Campaign</Title>
        </Grid>
      </Grid>

      <CampaignEditor
        campaign={campaign}
        discounts
        editMode={true}
        onValueChange={handleValueChange}
      />
      <Grid item xs={12}>
      <Button color="primary" variant="contained" onClick={handleCampaignSubmit}>
        Save
      </Button>
      </Grid>
    </>
  )
}

export default AddCampaignScreen
