import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Pagination from '@material-ui/lab/Pagination'

import Title from '../components/Title'
import Spinner from '../components/spinner'
import { CustomerEditor } from './CustomerEditor.'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import {
  ConsentedCustomersDocument,
  ConsentedCustomersCountDocument,
} from '../generated'
import { FormControlLabel, Switch } from '@material-ui/core'

import { Customer } from '../generated';

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  clickableRow: {
    cursor: 'pointer',
  },
}))


const CustomersTable = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  const headers = [
    'Created',
    'Consent Marketing',
    'Marketing consent date',
    'Phone',
    'Consent phone',
    'Consent phone date',
    'Email',
    'Consent email',
    'Consent email date',
    'Loyalty',
    'Loyalty date',
    'Loyalty purchases',  
  ]

  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const CustomersScreen = () => {
  const [open, setOpen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [loyaltyOnly, setLoyaltyOnly] = useState(false)
  const [filterPhone, setFilterPhone] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  
  const ConsentedCustomers = () => {
    const variables = {
      input: {
        LoyaltyOnly: loyaltyOnly,
        phoneNumber: filterPhone,
        Limit: pageSize,
        Offset: (page - 1) * pageSize,
      },
    };
    const { loading, data, error, refetch } = useQuery(ConsentedCustomersDocument, { variables })
    const { data: countData, refetch: countRefetch } = useQuery(ConsentedCustomersCountDocument)
  
    useEffect(() => {
      if (data) refetch()
    }, [data, refetch])
    useEffect(() => {
      if (countData) {
        countRefetch()
        console.log('countData', countData)
        setTotal(countData.consentedCustomersCount || 0)
      }
    }, [countData, countRefetch])
  
    const formatDate = (date: string) => date.replace('T', ' ').slice(0, 16)

    const handleRowClick = (customer: Customer) => {
      setSelectedCustomer(customer);
      setIsEditorOpen(true);
    };


    if (loading || !data) return <><Spinner /></>
    if (error) return <p>Error : {JSON.stringify(error, null, 2)}</p>
  
    return (
      <>
        {data.consentedCustomers.map((customer: any) => (
        <TableRow key={customer.id} onClick={() => handleRowClick(customer)} className="clickable-row">
            <TableCell>{formatDate(customer.createdAt)}</TableCell>
            <TableCell>{customer.marketingConsent ? 'Yes' : 'No'}</TableCell>
            <TableCell>{customer.consentedToMarketingAt ? formatDate(customer.consentedToMarketingAt) : '' }</TableCell>
            <TableCell>{customer.phoneNumber}</TableCell>
            <TableCell>{customer.consentPhone ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.consentPhoneAt ? formatDate(customer.consentPhoneAt) : '' }</TableCell>
            <TableCell>{customer.email}</TableCell>
            <TableCell>{customer.consentEmail ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.consentEmailAt ? formatDate(customer.consentEmailAt) : '' }</TableCell>
            <TableCell>{customer.loyaltySignup ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.loyaltySignupAt ? formatDate(customer.loyaltySignupAt) : '' }</TableCell>
            <TableCell>{customer.loyaltyPurchases }</TableCell>
          </TableRow>
        ))}
      </>
    )
  }

  const handleEditorClose = () => {
    setIsEditorOpen(false);
    setSelectedCustomer(null);
  };
  const onValueChange = (value: any, key: keyof Customer) => {
    if (selectedCustomer) {
      setSelectedCustomer({
        ...selectedCustomer,
        [key]: value,
      });
    }
  }

  const handleFilterPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterPhone(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Title>Customers</Title>
        </Grid>
        {(!isEditorOpen || !selectedCustomer) && (
          <>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    onChange={() =>
                      setLoyaltyOnly((prevLoyaltyOnly) => !prevLoyaltyOnly)
                    }
                    value={loyaltyOnly}
                    checked={loyaltyOnly}
                  />
                }
                label="Loyalty customers only"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <TextField
                  label="Filter by Phone"
                  value={filterPhone}
                  onChange={handleFilterPhoneChange}
                />
              </Box>
            </Grid>

            <CustomersTable>
              <ConsentedCustomers />
            </CustomersTable>
            <Grid item xs={12}>
              <Pagination siblingCount={6} count={Math.ceil(total/pageSize)} page={page} onChange={handleChange} />
            </Grid>
          </>
        )}
            
        {isEditorOpen && selectedCustomer && (
        <CustomerEditor
          customer={selectedCustomer}
          onValueChange={onValueChange}
          onClose={handleEditorClose}
        />
        )}

      </Grid>

      {/* {open && (
        <CreateDiscount open={open} setOpen={setOpen} activeOnly={activeOnly} />
      )} */}
    </>
  )
}

export default CustomersScreen
