import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import Title from '../components/Title';
import Spinner from '../components/spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { FormControlLabel, Switch } from '@material-ui/core';
import { CampaignEditor } from './CampaignEditor';
import { Campaign } from '../generated';
import { format, parseISO } from 'date-fns';

const CAMPAIGNS_QUERY = gql`
  query Campaigns {
    campaigns {
      id
      name
      description
      autoDiscount
      discountId
      discountType
      discountValue
      startDate
      endDate
      discount {
        id
        code
      }
    }
  }
`;

const DISCOUNTS_QUERY = gql`
  query Discounts($activeOnly: Boolean) {
    discounts(activeOnly: $activeOnly) {
      id
      code
    }
  }
`;

const UPDATE_CAMPAIGN_MUTATION = gql`
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
      name
      description
      autoDiscount
      discountId
      discountType
      discountValue
      startDate
      endDate
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  clickableRow: {
    cursor: 'pointer',
  },
}));

const CampaignsTable = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const headers = ['Name', 'Description'];

  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const CampaignsScreen = () => {
  const { loading, error, data } = useQuery(CAMPAIGNS_QUERY);
  const { data: discountsData } = useQuery(DISCOUNTS_QUERY, {
    variables: { activeOnly: true },
  });
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_MUTATION);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 10; // Example page size
  const total = data ? data.campaigns.length : 0;

  const handleRowClick = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsEditorOpen(true);
  };

  const handleEditorClose = () => {
    setIsEditorOpen(false);
    setSelectedCampaign(null);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onValueChange = (value: any, key: keyof Campaign) => {
    console.log('Here');
    if (selectedCampaign) {
      const updatedCampaign = {
        ...selectedCampaign,
        [key]: value,
      };
      updateCampaign({
        variables: {
          input: {
            id: updatedCampaign.id,
            name: updatedCampaign.name,
            autoDiscount: updatedCampaign.autoDiscount,
            discountId: updatedCampaign.discountId,
            discountType: updatedCampaign.discountType,
            discountValue: updatedCampaign.discountValue,
            description: updatedCampaign.description,
          },
        },
      });

      setSelectedCampaign(updatedCampaign);
    }
  };

  if (loading || !data) return <><Spinner /></>;
  if (error) return <p>Error : {JSON.stringify(error, null, 2)}</p>;

  return (
    <>
      <Grid container spacing={3}>
        {(!isEditorOpen || !selectedCampaign) && (
          <>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/create-campaign"
                  style={{ marginTop: '12px' }}
                >
                  + CREATE CAMPAIGN
                </Button>
              </Box>
            </Grid>

            <CampaignsTable>
              {data.campaigns.slice((page - 1) * pageSize, page * pageSize).map((campaign: Campaign) => (
                <TableRow key={campaign.id} onClick={() => handleRowClick(campaign)} className="clickable-row">
                  <TableCell>{campaign.name}</TableCell>
                  <TableCell>{campaign.description}</TableCell>
                </TableRow>
              ))}
            </CampaignsTable>
            <Grid item xs={12}>
              <Pagination siblingCount={6} count={Math.ceil(total / pageSize)} page={page} onChange={handleChange} />
            </Grid>
          </>
        )}

        {isEditorOpen && selectedCampaign && (
          <CampaignEditor
            campaign={selectedCampaign}
            discounts={discountsData?.discounts || []}
            editMode={true}
            updating={false}
            onValueChange={onValueChange}
            onClose={handleEditorClose}
          />
        )}
      </Grid>
    </>
  );
};

export default CampaignsScreen;