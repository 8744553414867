import React, { ChangeEvent, useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import {
  Customer
} from '../generated'
import { Link } from 'react-router-dom'
import Title from '../components/Title'

const GET_CUSTOMER_ORDERS_QUERY = gql`
  query getCustomerOrders($filters: OrderFilters) {
    filteredOrders(filters: $filters) {
      id
      orderDate
      status
      totalPrice
      discount {
        id
        type
        reduction
      }
      orderItems {
        id
        product {
          id
          texts {
            locale
            name
          }
        }
        quantity
        price
      }
    }
  }
`;

const UPDATE_CUSTOMER_MUTATION = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

// Omit the orders field from the Customer type
type CustomerWithoutOrders = Omit<Customer, 'orders'>;

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 'min-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '4px',
  },
  image: {
    height: '225px',
    border: '1px solid #333',
    borderRadius: '6px',
    marginLeft: '6px',
  },
  labelImage: {
    width: '70px',
    marginRight: '4px',
    '&:last-child': { marginRight: 0 },
  },
  infoRow: { marginTop: '0.5rem', marginBottom: '0.5rem' },
  label: { fontWeight: 700 },

  productLabel: {
    textAlign: 'center',
    fontSize: '25px',
    color: '#000',
    minWidth: '178px',
    fontFamily: 'Century Expanded',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  field: { margin: '12px 6px', '&:first-child': { marginLeft: 0 } },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '12px',
  },
  formContainer: { width: '475px' },

    backArrow: {
      color: theme.palette.primary.main,
      '&:hover': { textDecoration: 'underline' },
    },

}))

export const CustomerEditor = ({
  customer,
  onValueChange,
  onClose,
}: {
  customer: CustomerWithoutOrders
  onValueChange: (value: any, key: keyof Customer) => void
  onClose: () => void
}) => {
  const classes = useStyles()

  const [updateCustomerMutation] = useMutation(UPDATE_CUSTOMER_MUTATION)
  const saveCustomer = async() => {
    const { __typename, createdAt, ...rest } = customer;
    await updateCustomerMutation({
      variables: {
        input: {
          ...rest
        }
      }
    })
    onClose();
  }
  const [orders, setCustomerOrders] = useState<Customer['orders']>([])
  const [getCustomerOrdersQuery, { data }] = useLazyQuery(GET_CUSTOMER_ORDERS_QUERY); // Destructure the second element to access data
  const getCustomerOrders = async () => {
    await getCustomerOrdersQuery({
      variables: {
        filters: {
          customerId: customer.id,
        },
      },
    });
    if (data && data.filteredOrders) {
      setCustomerOrders(data.filteredOrders); // Update the customerOrders state variable with the query result
    }
  };

  useEffect(() => {
    getCustomerOrders()
  }, [data]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link className={classes.backArrow} onClick={onClose} to="#">
            &larr; Go back
          </Link>
          <Title>{`${ customer.email || customer.phoneNumber || customer.id }`}</Title>
          <div style={{ marginBottom: '10px' }}>{ `(${customer.id})` }</div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.formContainer}>
        <Grid item xs={6}>
          <FormControl variant="outlined">
            <TextField
              label={'Phone number'}
              className={classes.field}
              value={customer.phoneNumber}
              onChange={(e) => onValueChange(e.target.value, 'phoneNumber')}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined">
            <TextField
              label={'E-mail'}
              className={classes.field}
              value={customer.email}
              onChange={(e) => onValueChange(e.target.value, 'email')}
              variant="outlined"
          
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer.marketingConsent}
                  onChange={(e) => onValueChange(e.target.checked, 'marketingConsent')}
                  color="primary"
                />
              }
              label="Marketing consent"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Consented to Marketing At"
            type="date"
            value={customer.consentedToMarketingAt ? customer.consentedToMarketingAt.split('T')[0] : ''}
            onChange={(e) => onValueChange(e.target.value, 'consentedToMarketingAt')}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={6}>
          <FormControl variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer.loyaltySignup || false}
                  onChange={(e) => onValueChange(e.target.checked, 'loyaltySignup')}
                  color="primary"
                />
              }
              label="Loyalty program"
            />
          </FormControl>
        </Grid>
        <Grid item xs={9} sm={6}>
          <TextField
            label="Joined loyalty program on"
            type="date"
            value={customer.loyaltySignupAt ? customer.loyaltySignupAt.split('T')[0] : ''}
            onChange={(e) => onValueChange(e.target.value, 'loyaltySignupAt')}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
          label="Loyalty Purchases"
          type="number"
          value={customer.loyaltyPurchases || 0}
          onChange={(e) => onValueChange(Number(e.target.value), 'loyaltyPurchases')}
          variant="outlined"
          fullWidth
        />
      </Grid>        
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title>Orders</Title>
          <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Discount Reduction</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.id}</TableCell>
                <TableCell>{order.orderDate}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>{order.totalPrice}</TableCell>
                <TableCell>{order.discount?.reduction}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '20px'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={saveCustomer}
        >
          Save
        </Button>
      </Grid>
    </>
)
}
