import React, { ChangeEvent, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation, gql } from '@apollo/client'

import Spinner from '../components/spinner'
import Grid from '@material-ui/core/Grid'
import {
  Box,
  Button,
  IconButton,
  TextField,
  TextareaAutosize,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import FormControl from '@material-ui/core/FormControl'
import {
  Campaign,
  Discount,
  Locale
} from '../generated'
import { Link } from 'react-router-dom'
import Title from '../components/Title'
import { DiscountType } from '../generated'

const CAMPAIGN_ACTIVITIES_QUERY = gql`
  query CampaignActivities($input: CampaignActivitiesInput!) {
    campaignActivitiesByCampaign(input: $input) {
      id
      campaignId
      customerId
      discountId
      machineId
      productId
      sendDate
      createdAt
      updatedAt
      customer {
        phoneNumber
      }
    }
  }
`;

const SAVE_CUSTOMERS_TO_CAMPAIGN = gql`
  mutation SaveCustomersToCampaign($input: SaveCustomersToCampaignInput!) {
    saveCustomersToCampaign(input: $input)
  }
`;

const REMOVE_CUSTOMER_FROM_CAMPAIGN = gql`
  mutation RemoveCustomerFromCampaign($input: RemoveCustomerFromCampaignInput!) {
    removeCustomerFromCampaign(input: $input)
  }
`;

const useStyles = makeStyles((theme) => ({
  field: { margin: '12px 6px', '&:first-child': { marginLeft: 0 } },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '12px',
  },
  formContainer: { width: '70%' },
  backArrow: {
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' },
  },
  fullWidth: { width: '100%' },
}));

export const CampaignEditor = ({
  campaign,
  discounts,
  editMode,
  onValueChange,
  updating,
  onClose,
}: {
  campaign: Campaign
  discounts: Discount[]
  editMode: boolean
  onValueChange: (value: any, key: keyof Campaign) => void
  updating: boolean,
  onClose: () => void
}) => {
  const classes = useStyles()
  const [showTextarea, setShowTextarea] = useState(false);
  const [customers, setCustomers] = useState('');
  const [autoDiscount, setAutoDiscount] = useState(campaign.autoDiscount || false);
  const [discountType, setDiscountType] = useState<DiscountType | ''>('');
  const [discountValue, setDiscountValue] = useState<number | ''>('');

  const [saveCustomersToCampaign] = useMutation(SAVE_CUSTOMERS_TO_CAMPAIGN);
  const [removeCustomerFromCampaign] = useMutation(REMOVE_CUSTOMER_FROM_CAMPAIGN);


  const { loading, error, data, refetch } = useQuery(CAMPAIGN_ACTIVITIES_QUERY, {
    variables: { input: { campaignId: campaign.id } },
  });

  const handleSaveClick = async () => {
    const customerArray = (customers.trimEnd() + '\n').split('\n').map(phoneNumber => phoneNumber.trim()).filter(phoneNumber => phoneNumber !== '');
    try {
      await saveCustomersToCampaign({
        variables: {
          input: {
            campaignId: campaign.id,
            customers: customerArray,
          },
        },
      });
      await refetch();
      setShowTextarea(false);
      setCustomers('');
    } catch (error) {
      console.error('Error saving customers to campaign:', error);
    }
  };
  const handleCancelClick = () => {
    setShowTextarea(false);
    setCustomers('');
  };

  const handleDeleteClick = async (customerId: string) => {
    try {
      await removeCustomerFromCampaign({
        variables: {
          input: {
            campaignId: campaign.id,
            customerId,
          },
        },
      });
      await refetch();
    } catch (error) {
      console.error('Error removing customer from campaign:', error);
    }
  };  

  return (
    <>
      <Grid container spacing={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Link className={classes.backArrow} onClick={onClose} to="#">
              &larr; Go back
            </Link>
            <Title>{`${ campaign.name }`}</Title>
          </Grid>


          {editMode && (
            <>
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <TextField
                    label={'Name'}
                    className={classes.field}
                    value={ campaign.name || undefined }
                    onChange={(e) => onValueChange(e.target.value || undefined, 'name')}
                    variant="outlined"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.fullWidth} variant="outlined">
                  <TextField
                    label={'Description'}
                    className={classes.field}
                    value={ campaign.description || undefined }
                    onChange={(e) => onValueChange(e.target.value || undefined, 'description')}
                    variant="outlined"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={campaign.autoDiscount || false }
                      value={ campaign.autoDiscount || undefined }
                      onChange={(e) => { setAutoDiscount(e.target.checked); onValueChange(e.target.checked, 'autoDiscount') }}
                      color="primary"
                    />
                  }
                  label="Automatically create Discount for each Customer"
                />
              </Grid>
              {!autoDiscount && (
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="discount-select-label">Select Discount for all customers in campaign</InputLabel>
                  <Select
                    labelId="discount-select-label"
                    value={campaign.discountId || ''}
                    onChange={(e) => onValueChange(e.target.value, 'discountId')}
                    label="Select Discount"
                  >
                    <MenuItem value={undefined}>No discount</MenuItem>
                    {discounts.map((discount) => (
                      <MenuItem key={discount.id} value={discount.id}>
                        {discount.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              )}
              {autoDiscount && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="discount-type-label">Discount Type</InputLabel>
                      <Select
                        labelId="discount-type-label"
                        value={campaign.discountType || null}
                        onChange={(e) => { setDiscountType(e.target.value as DiscountType); onValueChange(e.target.value as DiscountType, 'discountType') }}
                        label="Discount Type"
                      >
                        {Object.values(DiscountType).map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Discount Value"
                      type="number"
                      value={campaign.discountValue || null}
                      onChange={(e) => { setDiscountValue(Number(e.target.value)); onValueChange(Number(e.target.value), 'discountValue') }}
                      variant="outlined"
                      fullWidth
                      inputProps={{ min: -Infinity, max: Infinity }}
                    />
                  </Grid>
                </>
              )}

              <Grid container>
                <Grid item xs={5}>
                  <h2>Customers {data && data.campaignActivitiesByCampaign && `(${data.campaignActivitiesByCampaign.length})`}</h2>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      onClick={() => setShowTextarea(true)}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '12px' }}
                    >
                      + ADD CUSTOMERS
                    </Button>
                  </Box>
                </Grid>
                {showTextarea && (
                  <Grid item xs={12}>
                    <TextareaAutosize
                      rowsMin={5}
                      placeholder="Enter customer phone numbers, one per line"
                      value={customers}
                      onChange={(e) => setCustomers(e.target.value)}
                      style={{ width: '100%', marginTop: '12px' }}
                    />
                    <Grid item xs={12} style={{ marginTop: '12px' }}>
                      <Box display="flex" justifyContent="space-between">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveClick}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                { data && data.campaignActivitiesByCampaign && <Grid item xs={12}>
                  <Title>Customers</Title>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Sent date</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.campaignActivitiesByCampaign.map((activity: any) => (
                        <TableRow key={activity.id}>
                          <TableCell>{activity.customer.phoneNumber}</TableCell>
                          <TableCell>{activity.sendDate}</TableCell>
                          <TableCell>{ 
                            !activity.sendDate && 
                            <IconButton
                              onClick={() => handleDeleteClick(activity.customerId)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }</TableCell> 
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid> 
                }                        
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {updating && <Spinner />}
    </>
  )
}